import {
  AfterViewInit,
  Component, HostListener,
  inject,
  OnDestroy,
  OnInit,
} from '@angular/core';
import {StorageDataKey, StorageService} from "../../service/storage.service";
import {SsrCookieService} from "ngx-cookie-service-ssr";
import {AngularFireMessaging} from "@angular/fire/compat/messaging";
import {filter, skip, Subscription} from "rxjs";
import { ActivatedRoute } from '@angular/router';
import {SessionService} from "../../service/session.service";
import {UserRepositoryService} from "../../repository/user-repository.service";
import * as uuid from 'uuid';
import {TranslationsService} from "../../service/translations/translations.service";

@Component({
  selector: 'app-social-auth',
  standalone: true,
  imports: [],
  templateUrl: './social-auth.component.html',
  styleUrl: './social-auth.component.scss'
})
export class SocialAuthComponent implements OnInit, OnDestroy {
  firebase$?: Subscription;
  private storage =  inject(StorageService);
  private cookie =  inject(SsrCookieService);
  private afMessaging =  inject(AngularFireMessaging);
  private activeRoute =  inject(ActivatedRoute);
  private session = inject(SessionService);
  private userRepo = inject(UserRepositoryService);

  private returnUrl?: string;

  @HostListener('window:load',['$event'])
  onPageLoad(event: Event) {
    this.initGoogle();
  }

  ngOnInit(): void {
    this.activeRoute.queryParamMap.pipe(
      filter(data => data.has('returnUrl'))
    ).subscribe(data => {
      this.returnUrl = data.get('returnUrl');
    });
    this.firebase$ = this.afMessaging.getToken.subscribe(token => {
      if (token != null) {
        this.storage.setLocalData(StorageDataKey.fcmToken, token);
      }
      this.storage.setLocalData(StorageDataKey.browserId, this.cookie.get('browserId'));
    });
    this.initGoogle();
  }

  private initGoogle() {
    // @ts-ignore
    google.accounts.id.initialize({
      client_id: "857136627446-72lvhqb3ekanrng353vpq1ebf0mup83g.apps.googleusercontent.com",
      callback: this.handleCredentialResponse.bind(this),
      auto_select: false,
      cancel_on_tap_outside: true,
    });
    this.userRepo.meSubject$.pipe(
      skip(1)
    ).subscribe(data => {
      if (data == null) {
        // @ts-ignore
        google.accounts.id.prompt();
      }
    });
  }

  async handleCredentialResponse(response: any) {
    await this.session.login(response.credential, this.returnUrl);
  }

  ngOnDestroy() {
    this.firebase$.unsubscribe();
  }
}

@Component({
  selector: 'app-google-button',
  standalone: true,
  template: `<div [attr.id]="id" [attr.data-locale]="translationsService.getActiveLang()"></div>`,
})
export class GoogleButtonComponent implements OnInit, AfterViewInit {
  public readonly translationsService: TranslationsService = inject(TranslationsService);
  private activeRoute =  inject(ActivatedRoute);
  private returnUrl?: string;
  public id: string;

  @HostListener('window:load',['$event'])
  onPageLoad(event: Event) {
    this.initGoogle();
  }

  ngOnInit() {
    this.id = uuid.v4();
    this.activeRoute.queryParamMap.pipe(
      filter(data => data.has('returnUrl'))
    ).subscribe(data => {
      this.returnUrl = data.get('returnUrl');
    });
  }

  ngAfterViewInit() {
    try {
      this.initGoogle();
    } catch (e) {}
  }

  initGoogle() {
    // @ts-ignore
    google.accounts.id.renderButton(
      // @ts-ignore
      document.getElementById(this.id),
      { theme: "outline", size: "large", locale: this.translationsService.getActiveLang() }
    );
    console.log('render button ' + this.id);
  }
}
